<template>
	<div>
		<b-tabs
			v-if="(typeof resources != 'string')" v-model="activeTabIndex" 
			class="-plain pt-sm-4 pt-2 position-relative"
			:class="{ '-download': activeResource.download_link }"
		>
			<b-tab v-for="(resource, i) of resources" :key="i" :title="resource.title">
				<iframe :src="resource.link + '#toolbar=0&navpanes=0'" class="mt-3"></iframe>
			</b-tab>
			<b-button v-if="activeResource.download_link" :href="activeResource.download_link" target="_blank" @click="fireDownloadEvent(activeResource.download_link)" class="download-button" variant="outline-blue-button">Stiahnuť</b-button>
		</b-tabs>
		<div class="info-callout p-3">
			<img src="../../appzmudri/_layouts/default/img/info-icon.png"/>
			<p class="font-weight-bold">
				Ak vám nejde načítať dokument alebo kvíz, vyskúšajte niektoré z tipov:

				<ul>
					<li>Refresh stránky</li>
					<li>Prekliknutie sa na iné stránky a naspäť</li>
					<li>Odhlásenie/prihlásenie</li>
				</ul>

				V prípade pretrvávajúcich problémov nás kontaktuje na
				<a class="link" href="mailto:team@zmudri.sk">
					team@zmudri.sk
				</a>
			</p>
		</div>
	</div>
</template>

<script>
export default {
	props: {
		resources: {
			type: Array,
			required: true
		}
	},

	data() {
		return {
			activeTabIndex: 0
		}
	},
	methods: {
		fireDownloadEvent(materialUrl) {
			this.$wEvent.userLog('lesson.material.download', {material_url: materialUrl})
		}
	},

	computed: {
		activeResource() {
			return this.resources[this.activeTabIndex]
		}
	}
}
</script>

<style lang="scss" scoped>
.download-button {
	position: absolute;
	top: 2rem;
	right: 0;
}

@media only screen and (max-width: 575px) {
	.download-button {
		top: .5rem;
		max-width: 35%;
	}

	.tabs.-download ::v-deep .nav-tabs {
		max-width: 60%;
	}
}

.info-callout {
	font-family: 'poppins';
	max-width: 467px;
	background: #fff5d1;
	border: 1px solid #e5d8a7;
	border-radius: 4px;
	margin: 20px auto;
	display: flex;
	align-items: center;

	img {
		margin-right: 30px;
	}

	p {
		color: #998c5c;
		white-space: normal;
		margin: 0;

		a.link {
			font-weight: 700;
			color: inherit;
			text-decoration: underline;
		}
	}

	@media only screen and (max-width: 480px){
		max-width: 20em;
	}
}
</style>
